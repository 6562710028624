@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700;800&display=swap");

#raffle-results .animated-container {
    width: 100%;
}

#raffle-results .animated-container > div {
    width: calc(100% / 7);
    text-align: center;
    font-family: "Ubuntu", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#html5qr-code-full-region img[alt="Info icon"] {
    display: none;
}